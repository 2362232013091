@import 'variables';

.confirmation-dialog .mat-dialog-container {
  padding: 0;

  .mat-dialog-title {
    border-bottom: 1px solid #cfd2d5;
    padding: 12px 16px;
    margin: 0;

    &.warning {
      color: #f5a623;
    }

    &.error {
      color: $luma-alert-red;
    }

    .mat-icon {
      position: relative;
      top: 4px;
      margin-right: 8px;
    }
  }

  .mat-dialog-content {
    margin: 0;
    padding: 16px;
    line-height: 1.5;
    font-size: 16px;

    &.p-0 {
      padding: 0;
    }

    // p {
    //   margin: 8px 0;
    //   font-size: 16px;
    // }

    .error {
      font-size: 14px;
    }
  }

  .mat-dialog-actions {
    margin: 0;
    border-top: 1px solid #cfd2d5;
    justify-content: center;
    padding: 12px 16px;

    button {
      width: 140px;
    }

    .ml-md {
      margin-left: 16px;
    }

    .confirm-button {
      background: $luma-link;
      color: #fff; 

      &.w-180 {
        width: 180px;
      }
    }

    .cancel-button {
      background: #ddebfb;
      color: #4a90e2;
    }
  }
}

.upload-modal .mat-dialog-container {
  ngx-dropzone {
    font-size: 13px;
    background-color: #fafafb;
    color: black;
    &.custom-dropzone {
      padding: 10px;
      height: 200px;
      min-height: 200px;
      width: 94.5%;
      border: 2px dashed $luma-light-grey;
      border-radius: 5px;
      &.hovered {
        border-style: solid;
        background-color: #fefefe;
      }
    }
  }

  .fileIcon {
    color:$luma-light-grey;
  }

  .arrowIcon {
    color: $luma-brand-green;
    width: 30px;
    margin-right: -5px;
  }

  .checkCircle {
    color: $luma-brand-gray;
  }

  .checkCircle.completed {
    color: $luma-brand-green;
  }

  .errorCircle {
    color: #d0021b;
  }
  .closeButton {
    margin-left: 5px;
  }
  .arrowupIcon {
    position: relative; top: -1px; right: 3px;
  }

  .dropZoneOverlay {
    position: relative;
    top:-70px;
    left:175px;
    width: 100px;
  }
  .downloadIcons {
    position: absolute;
    top:-120px;
    left:10px;
  }
  .tryAgainButton {
    margin-top: 30px;
  }

  .directions {
    margin-top: -5px;
    & h4 {
      font-weight: bold;
    }
    & ol {
      margin-left:18px;
      padding: 0;
      margin-top: -18px;
    }
  }

  .filename {
    color:$luma-dark-blue-grey
  }

  .hidden {
    display: none!important;
  }

  .uploadResult {
    padding: 10px;
    height: 225px;
    min-height: 225px;
    width: 100%;
    border: 2px dashed $luma-light-grey;
    border-radius: 5px;
    font-size: 13px;
    background-color: #fafafb;
    color: black;
    margin-bottom: 36px;
    & div {
      text-align: center;
    }
  }

  .uploadResult.error {
    border-color: $alert-red!important;
  }

  .captionOr {
    position: absolute;
    top: -28px;
    left: 40px;
    color:$luma-brand-gray;
  }

  .doneButton {
    margin-left: 20px;
  }

  .buttonsBottom {margin-top: 5px; padding-bottom: 20px}

  .rowErrors {
    z-index: 1;
    position: relative;
    left: 265px;
    overflow: scroll;
    max-height: 300px;
    min-height: 60px;
    width:300px;
    background-color: #ffffff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
    padding: 10px 6px;
    color: #888888;
    border: solid 1px $luma-light-grey;
    border-radius: 2px;
    font-weight: 300;
    & div {
      margin-left: 5px;
      text-align: left;
    }
  }

  .errorsPopover:before {
    content: "";
    position: absolute;
    top: 47%;
    right:60px;
    border: solid 9px transparent;
    border-right-color: #fff;
    z-index: 3;
  }

  .errorsPopover .header , .warningsPopover .header {
    left: 265px;
    top: 1px;
    position: relative;
    z-index: 2;
    padding: 11px 12px!important;
    background-color: #f4f6f8;
    text-align: left;
    padding: 5px 5px;
    font-size: 13px;
    font-weight: bold;
    border-radius: 3px;
    border: solid 1px #cfd2d5;
    line-height: 1.15;
    color: #222222;
  }

  .errorsPopover .icons , .warningsPopover .icons{
    margin-right: 0px;
    & .closeButton {
      line-height: 14px;
      min-width: 0px;
      padding: 0px 5px;
      margin-right: -6px;
    }
  }

  .validationSection {
    margin:auto;
    text-align: center;
    color: $alert-red;
    & .viewErrorButton {
      color: $luma-link
    }
    & .viewWarningButton {
      color: $luma-link
    }
  }

  .validationSection div {
    margin-right: 15px;
  }

  .uploadResultButtons {
    z-index: 10;
  }

  .clearbitAttribution {
    text-decoration: none;
    text-align: right;
    margin: 8px 8px;
    color: $luma-dark-blue-grey;
    font-size: 12px;
  }

  .template_error_container {
    text-align: center;

    p {
      margin-top: 12px;
    }
  }
}

.w-80 {
  width: 80%;
}